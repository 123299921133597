import React, { useState, useContext } from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";
import * as styles from "./RestaurantBookingButton.module.scss";
import WaveIcon from "../../assets/images/icons/buta-wave.gif";
import MenuContext from "../../context/menu-context";
import Translator from "../../utilities/Translator";
import { keys } from "../../utilities/translations";
import { getRestaurantBookingURL } from "../../utilities/functions";

function RestaurantBookingButton(props) {
  const { restaurant, t } = props;
  const [showPopup, setShowPopup] = useState(false);
  const menuContext = useContext(MenuContext);
  const { currentLangCode } = menuContext;

  const hidePopup = () => {
    setShowPopup(false);
  };
  const bookingUrl = getRestaurantBookingURL(restaurant);
  if (!bookingUrl) return null;
  return (
    <>
      <button
        className={styles.reserveButton}
        onClick={() => {
          setShowPopup(true);
        }}
      >
        {t(keys.RESERVE_BUTTON_LABEL, currentLangCode)}
      </button>
      {showPopup &&
        ReactDom.createPortal(
          <div className={styles.popupWrap} role="dialog">
            <div
              className={styles.backdrop}
              role="button"
              onClick={hidePopup}
              onKeyPress={hidePopup}
              tabIndex="-1"
            >
              {t(keys.CLOSE_POPUP_LABEL, currentLangCode)}
            </div>
            <div className={styles.popupInnerWrap}>
              <div className={styles.popupBody}>
                <iframe
                  id="restaurante-mad-ramen"
                  title={t(keys.RESERVE_BUTTON_LABEL, currentLangCode)}
                  src="https://www.covermanager.com/reservation/module_restaurant/restaurante-mad-ramen/english"
                ></iframe>
              </div>
              <div className={styles.popupFooter}>
                <div className={styles.waveIconWrap}>
                  <img src={WaveIcon} alt="" />
                </div>
                <div className={styles.closeModalWrap}>
                  <button className={styles.closeModal} onClick={hidePopup}>
                    {t(keys.CLOSE_POPUP_LABEL, currentLangCode)}
                  </button>
                </div>
              </div>
            </div>
          </div>,
          document.getElementById("overlays")
        )}
    </>
  );
}
RestaurantBookingButton.proptTypes = {
  restaurant: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default Translator(RestaurantBookingButton);
