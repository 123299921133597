/**
 * Filter out menu buttons for restaurant
 * @param menus | object with nodes i.e. array of menus
 * @param restaurant | restaurant object
 * @return menus | return in same format after filtering array
 */
export function getFilteredMenusList(menus, restaurant) {
  if (restaurant.slug === "la-laguna") {
    const { nodes: menuItems } = menus;
    const filteredMenus = menuItems.filter((item) => {
      return item.slug !== "menu-del-medio-dia";
    });
    return { nodes: filteredMenus };
  }
  return menus;
}

/**
 * Add booking button after restaurant content
 * @param restaurant | restaurant object
 * @return string | URL for iframe
 */
export function getRestaurantBookingURL(restaurant) {
  if (restaurant?.slug === "albacete") {
    return "https://www.covermanager.com/reservation/module_restaurant/restaurante-mad-ramen/english";
  }
  return false;
}
